import React from "react";
import "../Digits_3D_holder.css";

function Digits_3D_B_holder({ letter, numbers }) {
  return (
    <>
      <div className="digits_3D_holder">
        <div className="A_holder">
          <h1>{letter}</h1>
        </div>
        <div className="digits-holder">
          {numbers.map((number, index) => (
            <div key={index} className="number-holder green-box">
              <h1>{number}</h1>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Digits_3D_B_holder;
