import React from "react";
import "./Bet_Result_Number.css";

function Bet_Result_Number({ text, background, color }) {
  const boxStyle = {
    background,
    color,
  };
  return (
    <>
      <div className="Bet_Result_Number">
        <div className="bet-result-number-holder" style={boxStyle}>
          {text}
        </div>
      </div>
    </>
  );
}

export default Bet_Result_Number;
