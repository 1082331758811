import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import ButtonCommon from "../../../Common-Component/ButtonCommon/ButtonCommon";
// import Bet_table from "../Bet_table/Bet_table";
import Advance_Draw from "../../../Common-Component/Common-Modal/Advance_Draw/Advance_Draw";
// import GameBetCheckBox from "../GameBetCheckBox/GameBetCheckBox";
import Bet_table_B from "../Bet_table_B/Bet_table_B";
import "./Game50_50_A.css";
import Timer_Holder from "../../../Common-Component/Timer_Holder/Timer_Holder";

function Game50_50_A() {
  const [modalShow, setModalShow] = useState(false);

  const pointsData = [
    { points: "6554", background: "#fff" },
    { points: "1644", background: "#FFEE03" },
    { points: "1454", background: "#fff" },
    { points: "1614", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "5254", background: "#FFEE03" },
    { points: "5254", background: "#fff" },
    { points: "1224", background: "#FFEE03" },
    { points: "3254", background: "#fff" },
    { points: "7254", background: "#FFEE03" },
    { points: "1214", background: "#fff" },
    { points: "3254", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "1284", background: "#FFEE03" },
    { points: "3254", background: "#fff" },
    { points: "1294", background: "#FFEE03" },
    { points: "8254", background: "#fff" },
    { points: "1264", background: "#FFEE03" },
    { points: "1854", background: "#fff" },
    { points: "1554", background: "#FFEE03" },
    { points: "1554", background: "#fff" },
    { points: "1854", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "1454", background: "#FFEE03" },
    { points: "1154", background: "#fff" },
    { points: "1274", background: "#FFEE03" },
    { points: "1154", background: "#fff" },
    { points: "1554", background: "#FFEE03" },
    { points: "1154", background: "#fff" },
    { points: "1754", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "1234", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "1251", background: "#FFEE03" },
    { points: "1258", background: "#fff" },
    { points: "1254", background: "#FFEE03" },
  ];

  // Split points data into columns
  const columns = [[], [], []];
  pointsData.forEach((item, index) => {
    columns[index % 3].push(item);
  });

  return (
    <>
      <section className="Game Game50_50_A">
        <div className="row me-0 ms-0">
          <div className="col-md-12">
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="select-game games-selected-info-holder">
                      <div className="row me-0 ms-0">
                        <div className="col-md-6 col-sm-6  border-right-holder">
                          <div className="text-holder">
                            <p>Cur. Draw Time: 02.15PM</p>
                          </div>

                          <div className="text-holder">
                            <p>Draw Date: 16/04/24</p>
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-6 ">
                          <div className="text-holder">
                            <p>Next Game: 02.30 PM</p>
                          </div>

                          <div className="text-holder">
                            <p>Coupon Value: 2</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="games-Advance-Draw-holder select-game">
                      <div className="heading-holder">
                        <h4>Family</h4>
                        <Form className="switch-box-holder">
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </div>
                      <div className="game-Advance-Draw">
                        <div className="Advance-Draw-btn-holder">
                          <ButtonCommon
                            className="Advance-Draw-btn"
                            text="Advance Draw(F7)"
                            onClick={() => setModalShow(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <div className="games-timer-holder select-game">
                      <Timer_Holder />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div className="">
                  <Bet_table_B
                    gameText={"Game 50A"}
                    resultText={"Result : 24"}
                  />
                </div>
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div className="bet-table-holder-Two">
                  <Bet_table_B
                    gameText={"Game 50B"}
                    resultText={"Result : 24"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Advance_Draw
        headingText={"Advance Draw"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Game50_50_A;
