import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ButtonCommon from '../../../Common-Component/ButtonCommon/ButtonCommon';

function GameBetCheckBox3d() {
  const [checkedState, setCheckedState] = useState([false, false, false]);
  const checkboxValues = ["STR", "BOX", "FP", "BP", "SP", "AP"];

  const handleCheckboxChange = (index) => {
    const updatedCheckedState = checkedState.map((item, idx) =>
      idx === index ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handleCheckboxOuterChange = (e) => {
    const allChecked = e.target.checked;
    setCheckedState(Array(checkedState.length).fill(allChecked));
  };

  return (
    <>
      <div className="GameBetCheckBox GameBetCheckBox3d">
        <div className="heading-holder">
          <h4>Select All</h4>
          <Form className="check-box-holder">
            <Form.Check
              label=""
              type="checkbox"
              onChange={handleCheckboxOuterChange}
              checked={checkedState.every(Boolean)}
            />
          </Form>
        </div>

        <div className="main-check-box-holder">
          <div className="row">
            {["A", "B"].map((value, index) => (
              <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-6 col-sm-6" key={value}>
                <div
                  className={`main-check-box ${checkedState[index] ? "active" : ""}`}
                >
                  <ButtonCommon
                    className={`check-box-btn ${index === 1 ? "green-btn " : "red-btn "}`}
                    onClick={() => handleCheckboxChange(index)}
                    text={
                      <>
                        <Form className="check-box-holder ">
                          <Form.Check
                            className=""
                            label=""
                            type="checkbox"
                            checked={checkedState[index]}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </Form>
                        {value}
                      </>
                    }
                  />
                  <div className="top-border-holder"></div>
                </div>
              </div>
            ))}
          </div>

          <div className="all_checkbox">
            <div className="row">
              {checkboxValues.map((value, index) => (
                <div className="col-md-12" key={value}>
                  <div className="all-bet-bet-box">
                    <h4>{value}</h4>
                    <Form className="check-box-holder">
                      <Form.Check 
                        label="" 
                        type="checkbox" 
                       
                      />
                    </Form>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameBetCheckBox3d;
