import React, { useState } from "react";
import { Form } from "react-bootstrap";

function GameBetCheckBoxPoint3d() {
  const checkboxValues = [
    10,
    20,
    30,
    40,
    50,
    100,
    200,
  ];

  return (
    <>
      <div className="GameBetCheckBox GameBetCheckBoxPoint3d">
        <div className="heading-holder">
          <h4>Points</h4>
        </div>

        <div className="main-check-box-holder">
          <div className="all_checkbox">
            <div className="row">
              {checkboxValues.map((value, index) => (
                <div className="col-md-12" key={value}>
                  <div className="all-bet-bet-box">
                    <h4>{value}</h4>
                    <Form className="check-box-holder">
                      <Form.Check label="" type="checkbox" />
                    </Form>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameBetCheckBoxPoint3d;
