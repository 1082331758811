import React from "react";
import "./Welcome.css";
import ButtonCommon from "../Common-Component/ButtonCommon/ButtonCommon";
import { Link } from "react-router-dom";

function Welcome() {
  return (
    <section className="Welcome">
      <div className="welcome-content-main">
        <div className="welcome-content">
          <div className="heading-holder">
            <h1>Welcome to Paras!</h1>
            <p>Get Ready to Embark on an Epic Adventure</p>
          </div>

          <div className="btn-holder">
            <Link to={"/game-2d"}>
              <ButtonCommon
                className={"Welcome_game_btn Welcome_game_btn_2d"}
                text={"2D Game"}
              />
            </Link>
            <Link to={"/game-3d"}>
              <ButtonCommon
                className={"Welcome_game_btn Welcome_game_btn_3d"}
                text={"3D Game"}
              />
            </Link>

            <Link to={"/game-50"}>
              <ButtonCommon
                className={"Welcome_game_btn Welcome_game_btn_50A"}
                text={"50A & 50B"}
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Welcome;
