import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import ButtonCommon from "../../ButtonCommon/ButtonCommon";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Toster_Modal from "../Toster_Modal/Toster_Modal";

function Change_Password_Modal(props) {
  const { headingText, btnText } = props;
  const [modalShow, setModalShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formGroups = [
    {
      controlId: "formBasicPassword",
      label: "Current Password",
      type: showPassword ? "text" : "password",
      placeholder: "Enter Current Password",
    },
    {
      controlId: "formBasicNewPassword",
      label: "New Password",
      type: "password",
      placeholder: "Enter New Password",
    },
  ];

  useEffect(() => {
    let timer;
    if (modalShow) {
      timer = setTimeout(() => {
        setModalShow(false);
        navigate("/login");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    return () => clearTimeout(timer);
  }, [modalShow, navigate]);

  return (
    <>
      <Modal
        className="Logout_Modal modal-holder"
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-holder">
            {formGroups.map((group, index) => (
              <Form.Group
                key={index}
                className="mb-3"
                controlId={group.controlId}
              >
                <Form.Label>{group.label}</Form.Label>
                <div
                  className={
                    group.controlId === "formBasicPassword"
                      ? "password-wrapper"
                      : ""
                  }
                >
                  <Form.Control
                    type={group.type}
                    placeholder={group.placeholder}
                  />
                  {group.controlId === "formBasicPassword" && (
                    <span
                      className="password-toggle-icon"
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </span>
                  )}
                </div>
              </Form.Group>
            ))}
          </Form>
        </Modal.Body>

        <ButtonCommon
          className={"Logout-btn"}
          text={btnText}
          onClick={() => {
            setModalShow(true);
            props.onHide();
          }}
        />
      </Modal>

      <Toster_Modal
        textmsg={"Password Updated Successfully!"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Change_Password_Modal;
