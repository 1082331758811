import React, { useEffect, useState } from "react";

function Timer_Holder() {
    const [timer, setTimer] = useState(900); // 4 minutes and 36 seconds in seconds

    useEffect(() => {
        const interval = setInterval(() => {
          setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);

      const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(secs).padStart(2, "0")}`;
      };
    
  return (
    <section className="Timer_Holder">
      <div className="timer-holder">
        <h4>{formatTime(timer)}</h4>
      </div>
    </section>
  );
}

export default Timer_Holder;
