import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./Result3D_Modal.css";
import "react-datepicker/dist/react-datepicker.css";
import Digits_3D_B_holder from "../../Digits_3D_holder/Digits_3D_B_holder/Digits_3D_B_holder";
import Digits_3D_A_holder from "../../Digits_3D_holder/Digits_3D_A_holder/Digits_3D_A_holder";

function Result3D_Modal(props) {
  const { headingText } = props;

  return (
    <>
      <Modal
        className="modal-holder Report_Modal How_To_Play_3D_Modal Result3D_Modal"
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="Result3D_result_holder">
            <div className="heading-holder">
              <h1>Result</h1>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 ">
                <Digits_3D_A_holder letter={"A"} numbers={[4, 6, 7]} />
              </div>

              <div className="col-md-6 col-sm-6">
                <Digits_3D_B_holder letter={"B"} numbers={[4, 6, 7]} />
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Animation_win_vdo /> */}
      </Modal>
    </>
  );
}

export default Result3D_Modal;
