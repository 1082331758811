import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ButtonCommon from "../../../Common-Component/ButtonCommon/ButtonCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function HeaderTwo_A({ onSelectGame }) {
  const [activeButton, setActiveButton] = useState("2D Game");
  const location = useLocation();

  useEffect(() => {
    // Extract the game type from the current location
    const path = location.pathname;
    let gameType = "2D Game"; // Default value
    if (path === "/game-2d") {
      gameType = "2D Game";
    } else if (path === "/game-3d") {
      gameType = "3D Game";
    } else if (path === "/game-50") {
      gameType = "Game_50_50";
    }
    setActiveButton(gameType);
  }, [location.pathname]);

  const handleButtonClick = (gameType) => {
    setActiveButton(gameType);
    onSelectGame(gameType);
  };
  return (
    <section className="HeaderTwo HeaderTwo_A">
      <div className="row me-0 ms-0">
        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-5 col-sm-5">
          <div className="games-holder">
            <div className="heading-holder">
              <h4>Select Game</h4>
            </div>
            <div className="game-type">
              <div className="game-type-holder">
                <Link to={"/game-2d"}>
                  <ButtonCommon
                    className={activeButton === "2D Game" ? "active" : ""}
                    text="2D Game"
                    onClick={() => handleButtonClick("2D Game")}
                  />
                </Link>
              </div>
              <div className="game-type-holder">
                <Link to={"/game-3d"}>
                  <ButtonCommon
                    className={activeButton === "3D Game" ? "active" : ""}
                    text="3D Game"
                    onClick={() => handleButtonClick("3D Game")}
                  />
                </Link>
              </div>
             

              <div className="game-type-holder">
                <Link to={"/game-50"}>
                  <ButtonCommon
                    className={activeButton === "Game_50_50" ? "active" : ""}
                    text="50A & 50B"
                    onClick={() => handleButtonClick("Game_50_50")}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-7 col-xl-5 col-lg-5 col-md-5 col-sm-5">
          <div className="games-info-holder">
            <div className="row me-0 ms-0">
              <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="profile-text-holder">
                  <div className="profile-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Home/profile.png"
                      }
                      className="Profile"
                    />
                  </div>
                  <div className="text-holder">
                    <p>Smita</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-8 col-lg-8 col-md-12">
                <div className="coin-point">
                  <div className="coin-btn-holder">
                    <div className="coins-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/Home/coins.png"
                        }
                        className="coins"
                      />
                    </div>
                    <p>3500.0</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-1 col-xl-2 col-lg-2 col-md-2 col-sm-2">
          <div className="games-info-holder time-date-holder">
            <div className="row me-0 ms-0">
              <div className="col-md-12">
                <div className="text-holder">
                  <p className="icon-holder">
                    {" "}
                    <FontAwesomeIcon icon={faCalendar} />{" "}
                  </p>
                  <p>16/04/24</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="text-holder">
                  <p className="icon-holder">
                    {" "}
                    <FontAwesomeIcon icon={faClock} />
                  </p>
                  <p>2:30 PM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeaderTwo_A;
