import React, { useState } from "react";
import "./Report_Modal.css";
import { Form, Modal, Table } from "react-bootstrap";
import ButtonCommon from "../../ButtonCommon/ButtonCommon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Report_Modal(props) {
  const { headingText } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <>
      <Modal
        className="Logout_Modal modal-holder Report_Modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="data-table-holder">
            <div className="heading-content-holder">
              <div className="range-btn-holder">
                <div>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={
                      <Form>
                        <Form.Control
                          className={"dat-range-btn"}
                          placeholder="From"
                          value={startDate ? startDate.toLocaleDateString() : ""}
                        />
                      </Form>
                    }
                  />
                </div>
                <div>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    customInput={
                      <Form>
                        <Form.Control
                          className={"dat-range-btn"}
                          placeholder="To"
                          value={endDate ? endDate.toLocaleDateString() : ""}
                        />
                      </Form>
                    }
                  />
                </div>
              </div>
              <div className="search-btn-holder">
                <ButtonCommon className={"search-btn"} text={"Search"} />
              </div>
            </div>
            <div>
              <Table className="data-table-content" bordered>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Game</th>
                    <th>Play Point</th>
                    <th>Win Points</th>
                    <th>End Points</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Smita</td>
                    <td>other</td>
                    <td>@sam</td>
                    <td>@sam</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default Report_Modal;
