import React from "react";
import "./ButtonCommon.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ButtonCommon({
    onClick,
    className,
    margin,
    text,
    border,
    background,
    borderRadius,
    fontSize,
    color,
    fontWeight,
    padding,
    icon,
    showHeart,
    isActive, 
}) {
    const buttonStyle = {
        background,
        borderRadius,
        border,
        fontSize,
        fontWeight,
        color,
        padding,
        margin,
        className,
    };
    return (
        <>
            <button onClick={onClick} type="button" className={isActive ? `Submit-btn active ${className}` : `Submit-btn ${className}`} style={buttonStyle}>
                {icon && (
                    <FontAwesomeIcon
                        icon={icon}
                        className={`heart-icon me-2 ₹{showHeart ? "heart-icon  active" : ""}`}
                    />
                )}
                {text}
            </button>
        </>
    );
}

export default ButtonCommon;