import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Game3D.css";
import ButtonCommon from "../../../Common-Component/ButtonCommon/ButtonCommon";
import Advance_Draw from "../../../Common-Component/Common-Modal/Advance_Draw/Advance_Draw";
import GameBetCheckBox3d from "../GameBetCheckBox/GameBetCheckBox3d";
import GameBetCheckBoxPoint3d from "../GameBetCheckBox/GameBetCheckBoxPoint3d";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Bet_card_holder from "../../../Common-Component/Bet_card_holder/Bet_card_holder";
import How_To_Play_3D_Modal from "../../../Common-Component/Common-Modal/How_To_Play_3D_Modal/How_To_Play_3D_Modal";
import Timer_Holder from "../../../Common-Component/Timer_Holder/Timer_Holder";

function Game3D() {
  const [modalShow, setModalShow] = useState(false);
  const [modalHowToPlayShow, setModalHowToPlayShow] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activePick, setActivePick] = useState("STR");
  const [betCardHolders, setBetCardHolders] = useState([
    {
      id: 1,
      headingText: "STR",
      numberText: "Number",
      numberDigits: "456",
      pointsText: "Points",
      pointsDigits: "10",
    },
    {
      id: 2,
      headingText: "BOX",
      numberText: "Number",
      numberDigits: "456",
      pointsText: "Points",
      pointsDigits: "10",
    },
    {
      id: 3,
      headingText: "BP",
      numberText: "Number",
      numberDigits: "456",
      pointsText: "Points",
      pointsDigits: "10",
    },
    {
      id: 4,
      headingText: "STR",
      numberText: "Number",
      numberDigits: "456",
      pointsText: "Points",
      pointsDigits: "10",
    },
  ]);

  const deleteBetCardHolder = (id) => {
    setBetCardHolders((prevHolders) =>
      prevHolders.filter((holder) => holder.id !== id)
    );
  };

  const togglePick = (pick) => {
    setActivePick(pick);
  };

  return (
    <>
      <section className="Game  Game3D">
        <div className="row me-0 ms-0">
          <div className="col-xl-2 col-lg-2 col-md-6">
            <GameBetCheckBox3d />
          </div>

          <div className="col-xl-1 col-lg-1 col-md-6  px-3 px-lg-0">
            <GameBetCheckBoxPoint3d />
          </div>

          <div className="col-xl-9 col-lg-9 col-md-12">
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="select-game games-selected-info-holder">
                      <div className="row me-0 ms-0">
                        <div className="col-md-6 col-sm-6  border-right-holder border-right-holder">
                          <div className="text-holder">
                            <p>Cur. Draw Time: 02.15PM</p>
                          </div>

                          <div className="text-holder">
                            <p>Draw Date: 16/04/24</p>
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-6 ">
                          <div className="text-holder">
                            <p>Next Game: 02.30 PM</p>
                          </div>

                          <div className="text-holder">
                            <p>Coupon Value: 2</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="games-Advance-Draw-holder select-game">
                      <div className="game-Advance-Draw">
                        <div className="Advance-Draw-btn-holder">
                          <ButtonCommon
                            className="Advance-Draw-btn pink-btn"
                            text="How to Play"
                            onClick={() => setModalHowToPlayShow(true)}
                          />
                        </div>

                        <div className="Advance-Draw-btn-holder">
                          <ButtonCommon
                            className="Advance-Draw-btn blue-btn"
                            text="Advance Draw(F7)"
                            onClick={() => setModalShow(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <div className="games-timer-holder select-game">
                      <Timer_Holder />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="bet_3d_table">
                  <Form className="bet_3d_table_form">
                    <Form.Group className="form-field-holder" controlId="">
                      <Form.Label>Add Number</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>

                    <Form.Group className="form-field-holder" controlId="">
                      <Form.Label>Range</Form.Label>
                      <Form.Control placeholder="From" />

                      <Form.Control placeholder="To" />
                    </Form.Group>
                    <div className="vr"></div>

                    <Form.Group className="form-field-holder" controlId="">
                      <Form.Label>Lucky Pick</Form.Label>
                      <div className="lucky-picks-holder">
                        <ButtonCommon
                          className={`picks pink-pick ${
                            activePick === "STR" ? "" : "active"
                          }`}
                          text="STR"
                          onClick={() => togglePick("STR")}
                        />
                        <ButtonCommon
                          className={`picks blue-pick ${
                            activePick === "BOX" ? "" : "active"
                          }`}
                          text="BOX"
                          onClick={() => togglePick("BOX")}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="form-field-holder" controlId="">
                      <Form.Label>Enter QTY</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
            <div className="bet-card-list">
              <div className="row">
                {betCardHolders.map((holder) => (
                  <div
                    key={holder.id}
                    className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-md-2 col-sm-6"
                  >
                    <Bet_card_holder
                      headingText={holder.headingText}
                      numberText={holder.numberText}
                      numberDigits={holder.numberDigits}
                      pointsText={holder.pointsText}
                      pointsDigits={holder.pointsDigits}
                      onDelete={() => deleteBetCardHolder(holder.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Advance_Draw
        headingText={"Advance Draw"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <How_To_Play_3D_Modal
        show={modalHowToPlayShow}
        onHide={() => setModalHowToPlayShow(false)}
      />
    </>
  );
}

export default Game3D;
