import React from 'react'
import "./Resultponits.css"

function Resultponits({points, background}) {
    const pointStyle ={
        background
    }
  return (
    <div className='Resultponits' style={pointStyle}><p>{points}</p></div>
  )
}

export default Resultponits