import React, { useState } from "react";
import "./Login.css";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ButtonCommon from "../Common-Component/ButtonCommon/ButtonCommon";
import Succsessfull_Modal from "../Common-Component/Common-Modal/Succsessfull_Modal/Succsessfull_Modal";
import Animation_win_vdo from "../Animation_win_vdo/Animation_win_vdo";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginClick = () => {
    setModalShow(true);
    setTimeout(() => {
      setModalShow(false);
      navigate("/");
    }, 3000);
  };

  return (
    <section className="login-section">
      <div className="Login">
        <div className="login-form">
          <div className="logo-text-holder">
            <h1>Paras</h1>
          </div>

          <Form className="form-holder">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username/ Terminal Code</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Username/ Terminal Code"
              />
            </Form.Group>

            <Form.Group
              className="mb-3 password-group"
              controlId="formBasicPassword"
            >
              <Form.Label>Password</Form.Label>
              <div className="password-wrapper">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                />
                <span
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
            </Form.Group>
            <ButtonCommon
              className={"login-btn"}
              text={"Login"}
              onClick={handleLoginClick}
            />
          </Form>
        </div>
      </div>
      <div className="note-holder">
        <h4>Note: 18+ for Amusement Only</h4>
      </div>

      <Succsessfull_Modal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
}

export default Login;
