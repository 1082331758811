import React from "react";
import "./Bet_card_holder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

function Bet_card_holder({headingText, numberText, numberDigits, pointsText, pointsDigits ,   onDelete }) {
  return (
    <>
      <div className="bet-card-holder">
        <div className="heading-holder">
          <h1>{headingText}</h1>
        </div>
        <div className="text-holder">
          <p>{numberText}</p>
          <p>{numberDigits}</p>
        </div>

        <div className="text-holder">
          <p>{pointsText}</p>
          <p>{pointsDigits}</p>
        </div>

        <div className="cross-holder">
          <FontAwesomeIcon className="close-icon" icon={faCircleXmark}    onClick={onDelete} />
        </div>
      </div>
    </>
  );
}

export default Bet_card_holder;
