import React from "react";
import { Modal } from "react-bootstrap";
import "./Toster_Modal.css"

function Toster_Modal(props) {
  const { textmsg } = props;
  return (
    <>
      <Modal
        className="Toster_Modal  modal-holder"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={true}
      >
        <Modal.Footer>
          <h4>{textmsg}</h4>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Toster_Modal;
