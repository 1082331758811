import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faLock, faSignOut } from "@fortawesome/free-solid-svg-icons";
import Resultponits from "../Common-Component/Resultponits/Resultponits";
import "./Header.css";
import Logout_Modal from "../Common-Component/Common-Modal/Logout_Modal/Logout_Modal";
import Change_Password_Modal from "../Common-Component/Common-Modal/Change_Password_Modal/Change_Password_Modal";
import Digits_3D_A_holder from "../Common-Component/Digits_3D_holder/Digits_3D_A_holder/Digits_3D_A_holder";
import Digits_3D_B_holder from "../Common-Component/Digits_3D_holder/Digits_3D_B_holder/Digits_3D_B_holder";

function Header({ selectedGame }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalPasswordShow, setPasswordModalShow] = React.useState(false);
  // Define the points data
  const pointsData = [
    { points: "6554", background: "#fff" },
    { points: "1644", background: "#FFEE03" },
    { points: "1454", background: "#fff" },
    { points: "1614", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "5254", background: "#FFEE03" },
    { points: "5254", background: "#fff" },
    { points: "1224", background: "#FFEE03" },
    { points: "3254", background: "#fff" },
    { points: "7254", background: "#FFEE03" },
    { points: "1214", background: "#fff" },
    { points: "3254", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "1284", background: "#FFEE03" },
    { points: "3254", background: "#fff" },
    { points: "1294", background: "#FFEE03" },
    { points: "8254", background: "#fff" },
    { points: "1264", background: "#FFEE03" },
    { points: "1854", background: "#fff" },
    { points: "1554", background: "#FFEE03" },
    { points: "1554", background: "#fff" },
    { points: "1854", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "1454", background: "#FFEE03" },
    { points: "1154", background: "#fff" },
    { points: "1274", background: "#FFEE03" },
    { points: "1154", background: "#fff" },
    { points: "1554", background: "#FFEE03" },
    { points: "1154", background: "#fff" },
    { points: "1754", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "1234", background: "#FFEE03" },
    { points: "1254", background: "#fff" },
    { points: "1251", background: "#FFEE03" },
    { points: "1258", background: "#fff" },
    { points: "1254", background: "#FFEE03" },
  ];

  // Split points data into columns
  const columns = [[], [], []];
  pointsData.forEach((item, index) => {
    columns[index % 3].push(item);
  });

  const location = useLocation();
  const shouldShowResultHolder = location.pathname !== "/game-50";

  const renderContent = () => {
    switch (selectedGame) {
      case "2D Game":
        return (
          <div className="header_2D_holder">
            <div className="row me-0 ms-0 ">
              {columns.map((column, colIndex) => (
                <div
                  className={`col-xl-4 col-lg-4 col-md-4 col-sm-12  ${
                    colIndex < 2 ? "border-right-holder" : ""
                  }`}
                  key={colIndex}
                >
                  <div className="result-points-holder">
                    {column.map((item, index) => (
                      <div className="points-holder" key={index}>
                        <Resultponits
                          points={item.points}
                          background={item.background}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case "3D Game":
        return (
          <>
            {/* 3d HEADER  START  */}
            <div className="header_3D_holder">
              <div className="row me-0 ms-0">
                <div className="col-md-6 col-sm-6 ">
                  <Digits_3D_A_holder letter="A" numbers={[4, 6, 7]} />
                </div>

                <div className="col-md-6 col-sm-6 ">
                  <Digits_3D_B_holder letter="B" numbers={[4, 6, 7]} />
                </div>
              </div>
            </div>
            {/* 3d HEADER  END  */}
          </>
        );
      case "Game_50_50":
        return (
          <>
            {/* 3d HEADER  START  */}
            <div className="header_50_holder">
              <div className="row me-0 ms-0">
                <div className="col-md-12">
                  <div className="select-game games-selected-info-holder">
                    <div className="row me-0 ms-0">
                      <div className="col-md-6 col-sm-6 border-right-holder">
                        <div className="text-holder">
                          <p>Last Sale Pts: 0.00</p>
                        </div>

                        <div className="text-holder">
                          <p>Last Trans Id: 1267783474</p>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6">
                        <div className="text-holder">
                          <p>Tr Code: -</p>
                        </div>

                        <div className="text-holder">
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 3d HEADER  END  */}
          </>
        );
      // case "50-50 B":
      //   return (
      //     <>
      //       {/* 3d HEADER  START  */}
      //       <div className="header_3D_holder">
      //         <div className="row me-0 ms-0">
      //           <div className="col-md-6 col-sm-6 ">
      //             <Digits_3D_A_holder letter="A" numbers={[4, 6, 7]} />
      //           </div>

      //           <div className="col-md-6 col-sm-6 ">
      //             <Digits_3D_B_holder letter="B" numbers={[4, 6, 7]} />
      //           </div>
      //         </div>
      //       </div>
      //       {/* 3d HEADER  END  */}
      //     </>
      //   );
      default:
        return null;
    }
  };
  return (
    <section className="Header">
      <div className="header-holder">
        <Navbar expand="lg" className="navbar-holder">
          <Container fluid>
            <Link to={"/"}>
              <Navbar.Brand className="logo-text" href="/">
                Paras
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="header-result-holder my-2 my-lg-0" navbarScroll>
                {shouldShowResultHolder && (
                  <div className="result-holder">
                    <div className="result-text-box">
                      <div>
                        <h4>Result</h4>
                        <p>2:00 PM</p>
                      </div>
                    </div>
                  </div>
                )}

                {renderContent()}
              </Nav>
              <Form className="icon-btn-holder">
                <Button
                  className="header-icon-btn"
                  onClick={() => setPasswordModalShow(true)}
                >
                  <FontAwesomeIcon icon={faLock} />
                </Button>

                <Link to={"/"}>
                  <Button className="header-icon-btn">
                    <FontAwesomeIcon icon={faHome} />
                  </Button>
                </Link>
                <Button
                  className="header-icon-btn"
                  onClick={() => setModalShow(true)}
                >
                  <FontAwesomeIcon icon={faSignOut} />
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <Logout_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        headingText={"Logout Notice"}
        textContent={
          "Would you like to log out? Until Next Time, Adventurer! Keep Exploring in Webtech."
        }
        btnText={"Logout"}
      />

      <Change_Password_Modal
        show={modalPasswordShow}
        onHide={() => setPasswordModalShow(false)}
        headingText={"Change Password"}
        btnText={"Update Password"}
      />
    </section>
  );
}

export default Header;
