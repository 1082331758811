import React, { useState } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import ButtonCommon from "../../ButtonCommon/ButtonCommon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Digits_3D_A_holder from "../../Digits_3D_holder/Digits_3D_A_holder/Digits_3D_A_holder";
import Digits_3D_B_holder from "../../Digits_3D_holder/Digits_3D_B_holder/Digits_3D_B_holder";
import Bet_Result_Number from "../../Bet_Result_Number/Bet_Result_Number";
import Animation_win_vdo from "../../../Animation_win_vdo/Animation_win_vdo";

function Result_3D_Modal(props) {
  const { headingText } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    10: false,
    30: false,
    50: false,
  });

  const handleSelectAll = () => {
    const newValue = !selectAll;
    setSelectAll(newValue);
    setCheckboxes({
      10: newValue,
      30: newValue,
      50: newValue,
    });
  };

  const handleCheckboxChange = (key) => {
    setCheckboxes((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <>
      <Modal
        className="Logout_Modal modal-holder Result2D_Modal How_To_Play_3D_Modal "
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="data-table-holder">
            <div className="heading-content-holder">
              <div className="range-btn-holder">
                <div>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={
                      <Form>
                        <Form.Control
                          className={"dat-range-btn"}
                          placeholder="From"
                          value={
                            startDate ? startDate.toLocaleDateString() : ""
                          }
                        />
                      </Form>
                    }
                  />
                </div>
                <div>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    customInput={
                      <Form>
                        <Form.Control
                          className={"dat-range-btn"}
                          placeholder="To"
                          value={endDate ? endDate.toLocaleDateString() : ""}
                        />
                      </Form>
                    }
                  />
                </div>
              </div>
              <div className="search-btn-holder">
                <ButtonCommon className={"search-btn"} text={"Search"} />
              </div>
            </div>
            <div className="table-holder">
              <Table className="data-table-content" bordered responsive>
                <thead>
                  <tr>
                    <th className="block-div">
                      <div className="main-block-holder">
                        17/05/2024 <br /> Game Time
                      </div>
                    </th>
                    <th className="heading-box red-box">Win Options</th>
                    <th className="heading-box green-box">Points</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="first-row">
                    <td>07:45 PM</td>
                    <td>
                      <div>
                        <Digits_3D_A_holder numbers={[4, 6, 7]} />
                        <div className="bet-result-number">
                          <Bet_Result_Number
                            background={"#F30417"}
                            color={"#fff"}
                            text="Box"
                          />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number
                            text={
                              <>
                                <p className="red-text">BP</p>
                                <p>89</p>
                                <p>89</p>
                                <p>89</p>
                              </>
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <Digits_3D_B_holder numbers={[4, 6, 7]} />
                        <div className="bet-result-number">
                          <Bet_Result_Number
                            background={"#44BCA3"}
                            color={"#fff"}
                            text="Box"
                          />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number
                            text={
                              <>
                                <p className="green-text">AP</p>
                                <p>89</p>
                              </>
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr className="first-row">
                    <td>07:45 PM</td>
                    <td>
                      <div>
                        <Digits_3D_A_holder numbers={[4, 6, 7]} />
                        <div className="bet-result-number">
                          <Bet_Result_Number
                            background={"#F30417"}
                            color={"#fff"}
                            text="Box"
                          />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number
                            text={
                              <>
                                <p className="red-text">BP</p>
                                <p>89</p>
                                <p>89</p>
                              </>
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <Digits_3D_B_holder numbers={[4, 6, 7]} />
                        <div className="bet-result-number">
                          <Bet_Result_Number
                            background={"#44BCA3"}
                            color={"#fff"}
                            text="Box"
                          />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number text="869" />
                          <Bet_Result_Number
                            text={
                              <>
                                <p className="green-text">AP</p>
                                <p>89</p>
                                <p>89</p>
                                <p>89</p>
                              </>
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Result_3D_Modal;
