import React from "react";
import { Modal } from "react-bootstrap";
import "./Result_2D_Modal.css";
import Animation_win_vdo from "../../../Animation_win_vdo/Animation_win_vdo";

function Result_2D_Modal(props) {
  const {} = props;
  return (
    <>
      <Modal
        className=" modal-holder Report_Modal Result_2D_Modal How_To_Play_3D_Modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="result-holder">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 border-bottom-holder">
                <div className="bets-result-list-holder">
                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">10</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">11</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">12</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">13</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">14</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">15</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">16</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">17</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">18</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box red-box">19</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 border-bottom-holder">
                <div className="bets-result-list-holder">
                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">30</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">31</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">32</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">33</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">34</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">35</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">36</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">37</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">38</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box blue-box">39</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div className="bets-result-list-holder">
                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">50</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">51</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">52</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">53</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">54</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">55</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">56</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">57</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">58</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bets-result-list">
                    <div className="row me-0 ms-0">
                      <div className="col-md-4">
                        <div className="bet-number-box box yellow-box">59</div>
                      </div>

                      <div className="col-md-8">
                        <div className="result-points-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                            <div className="col-md-6">
                              <div className="point-holder box">6</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Animation_win_vdo /> */}
      </Modal>
    </>
  );
}

export default Result_2D_Modal;
