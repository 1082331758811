import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import ButtonCommon from "../../ButtonCommon/ButtonCommon";
import "./Logout_Modal.css";
import { Link, useNavigate } from "react-router-dom";
import Toster_Modal from "../Toster_Modal/Toster_Modal";

function Logout_Modal(props) {
  const { headingText, textContent, btnText } = props;
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (modalShow) {
      timer = setTimeout(() => {
        setModalShow(false);
        navigate("/login");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    return () => clearTimeout(timer);
  }, [modalShow, navigate]);

  return (
    <>
      <Modal
        className="Logout_Modal modal-holder"
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{textContent}</p>
        </Modal.Body>

        <ButtonCommon
          className={"Logout-btn"}
          text={btnText}
          onClick={() => {
            setModalShow(true);
            props.onHide();
          }}
        />
      </Modal>

      <Toster_Modal
        textmsg={"Logout Successfully!"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Logout_Modal;
