import React from "react";
import "./How_To_Play_3D_Modal.css";
import { Modal, Table } from "react-bootstrap";
import Win_Option from "../../Win_Option/Win_Option";

function How_To_Play_3D_Modal(props) {

  return (
    <>
      <Modal
        className="modal-holder Report_Modal How_To_Play_3D_Modal"
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="How_To_Play_table_holder">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>Play</th>
                  <th>Example Number</th>
                  <th>Win Option</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Straight</td>
                  <td>1 2 3</td>
                  <td>
                    <div className="win-option-list">
                      <Win_Option options={[1, 2, 3]} />
                    </div>
                  </td>
                  <td>900*10=9000</td>
                </tr>

                <tr>
                  <td>Box-3-way</td>
                  <td>1 1 2</td>
                  <td>
                    <div className="win-option-list">
                      <Win_Option options={[1, 1, 2]} />
                      <Win_Option options={[1, 2, 1]} />
                      <Win_Option options={[2, 1, 1]} />
                    </div>
                  </td>
                  <td>300*10=3000</td>
                </tr>

                <tr>
                  <td>Front Pair</td>
                  <td>1 1 X</td>
                  <td>
                    <div className="win-option-list">
                      <Win_Option options={[1, 2, "X"]} />
                    </div>
                  </td>
                  <td>90*10=900</td>
                </tr>

                <tr>
                  <td>Back Pair</td>
                  <td>X 1 3</td>
                  <td>
                    <div className="win-option-list">
                      <Win_Option options={[1, 2, "X"]} />
                    </div>
                  </td>
                  <td>90*10=900</td>
                </tr>

                <tr>
                  <td>Back Pair</td>
                  <td>X 1 3</td>
                  <td>
                    <div className="win-option-list">
                      <Win_Option options={["X", 2, 1]} />
                    </div>
                  </td>
                  <td>90*10=900</td>
                </tr>

                <tr>
                  <td>Split Pair</td>
                  <td>1 X 3</td>
                  <td>
                    <div className="win-option-list">
                      <Win_Option options={[1, "X", 1]} />
                    </div>
                  </td>
                  <td>90*10=900</td>
                </tr>

                <tr>
                  <td>Any Pair</td>
                  <td>1 2 3</td>
                  <td>
                    <div className="win-option-list">
                      <Win_Option options={[1, 1, "X"]} />
                      <Win_Option options={["X", 1, 2]} />
                      <Win_Option options={[1, "X", 2]} />
                    </div>
                  </td>
                  <td>30*10=300</td>
                </tr>

                <tr>
                  <td>Box-6-way</td>
                  <td></td>
                  <td>
                    <div>
                      <div className="win-option-list">
                        <Win_Option options={[1, 1, 2]} />
                        <Win_Option options={[1, 2, 1]} />
                        <Win_Option options={[2, 1, 1]} />
                      </div>
                      <div className="win-option-list other">
                        <Win_Option options={[1, 1, 2]} />
                        <Win_Option options={[1, 2, 1]} />
                        <Win_Option options={[2, 1, 1]} />
                      </div>
                    </div>
                  </td>
                  <td>300*10=3000</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default How_To_Play_3D_Modal;
