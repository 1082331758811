import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "./index.css";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Welcome from "./components/Welcome/Welcome";
import Login from "./components/Login/Login";
import HeaderTwo from "./components/home/Game/HeaderTwo/HeaderTwo";
import Game2D from "./components/home/Game/Game2D/Game2D";
import Game3D from "./components/home/Game/Game3D/Game3D";
import Result3D_Modal from "./components/Common-Component/Common-Modal/Result3D_Modal/Result3D_Modal";
import Result_2D_Modal from "./components/Common-Component/Common-Modal/Result_2D_Modal/Result_2D_Modal"; // Import the 2D modal component
import Game50_50_A from "./components/home/Game/Game50_50_A/Game50_50_A";
import HeaderTwo_A from "./components/home/Game/HeaderTwo_A/HeaderTwo_A";

function App() {
  const location = useLocation();
  const [selectedGame, setSelectedGame] = useState("2D Game");
  const [modal3DShow, setModal3DShow] = useState(false);
  const [modal2DShow, setModal2DShow] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const showHeaderFooter =
    location.pathname !== "/" && location.pathname !== "/login";

  const handleResize = () => {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    const isNarrowScreen = window.innerWidth < 768;
    setShowImage(isNarrowScreen && !isLandscape);
  };

  useEffect(() => {
    // Initial check
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const showModal = () => {
      if (location.pathname === "/game-2d") {
        setModal2DShow(true);
        setTimeout(() => setModal2DShow(false), 3000); // Hide modal after 3 seconds
      } else if (location.pathname === "/game-3d") {
        setModal3DShow(true);
        setTimeout(() => setModal3DShow(false), 3000); // Hide modal after 3 seconds
      }
      else if (location.pathname === "/game-50") {
        setModal3DShow(true);
        setTimeout(() => setModal3DShow(false), 3000); // Hide modal after 3 seconds
      }
    };
  
    // Show modal immediately on load if path matches
    showModal();
  
    // Set interval to show modal every 15 minutes
    const intervalTime = 15 * 60 * 1000; // 15 minutes in milliseconds
    const interval = setInterval(showModal, intervalTime);
  
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [location.pathname]);

  return (
    <>
      {!showImage && (
        <>
          {showHeaderFooter && <Header selectedGame={selectedGame} />}
          {showHeaderFooter && location.pathname !== "/game-50" && (
            <HeaderTwo onSelectGame={setSelectedGame} />
          )}
          {showHeaderFooter && location.pathname === "/game-50" && (
            <HeaderTwo_A onSelectGame={setSelectedGame} />
          )}
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/game-2d" element={<Game2D />} />
            <Route path="/game-3d" element={<Game3D />} />
            <Route path="/game-50" element={<Game50_50_A />} />
            <Route path="/login" element={<Login />} />
          </Routes>
          {showHeaderFooter && <Footer />}
          <Result3D_Modal
            show={modal3DShow}
            onHide={() => setModal3DShow(false)}
          />
          <Result_2D_Modal
            show={modal2DShow}
            
            onHide={() => setModal2DShow(false)}
          />
        </>
      )}
      {showImage && (
        <div className="landscape_img-holder">
          <img
            className="landscape_img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/Home/portable_landscape_img.jpg"
            }
            alt="landscape_img"
          />
        </div>
      )}
    </>
  );
}

export default App;
