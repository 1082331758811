import React, { useState } from "react";
import "./Result2D_Modal.css";
import { Form, Modal, Table } from "react-bootstrap";
import ButtonCommon from "../../ButtonCommon/ButtonCommon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Animation_win_vdo from "../../../Animation_win_vdo/Animation_win_vdo";

function Result2D_Modal(props) {
  const { headingText } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    10: false,
    30: false,
    50: false,
  });

  const handleSelectAll = () => {
    const newValue = !selectAll;
    setSelectAll(newValue);
    setCheckboxes({
      10: newValue,
      30: newValue,
      50: newValue,
    });
  };

  const handleCheckboxChange = (key) => {
    setCheckboxes((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <>
      <Modal
        className="Logout_Modal modal-holder Result2D_Modal How_To_Play_3D_Modal "
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="data-table-holder">
            <div className="heading-content-holder">
              <div className="range-btn-holder">
                <div>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={
                      <Form>
                        <Form.Control
                          className={"dat-range-btn"}
                          placeholder="From"
                          value={
                            startDate ? startDate.toLocaleDateString() : ""
                          }
                        />
                      </Form>
                    }
                  />
                </div>
                <div>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    customInput={
                      <Form>
                        <Form.Control
                          className={"dat-range-btn"}
                          placeholder="To"
                          value={endDate ? endDate.toLocaleDateString() : ""}
                        />
                      </Form>
                    }
                  />
                </div>
              </div>
              <div className="search-btn-holder">
                <div className="select-bet-holder">
                  <Form className="check-box-holder">
                    <div className="">
                      <Form.Check
                        label="Select All"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </div>
                    <Form.Check
                      type="checkbox"
                      checked={checkboxes[10]}
                      onChange={() => handleCheckboxChange(10)}
                      label="10"
                    />
                    <Form.Check
                      label="30"
                      type="checkbox"
                      checked={checkboxes[30]}
                      onChange={() => handleCheckboxChange(30)}
                    />
                    <Form.Check
                      label="50"
                      type="checkbox"
                      checked={checkboxes[50]}
                      onChange={() => handleCheckboxChange(50)}
                    />
                  </Form>
                </div>

                <ButtonCommon className={"search-btn"} text={"Search"} />
              </div>
            </div>
            <div className="table-holder">
              <Table className="data-table-content" bordered responsive>
                <thead>
                  <tr>
                  <th className="block-div">
                      <div className="main-block-holder">
                        17/05/2024 <br /> Game Time
                      </div>
                    </th>
                    <th className="heading-box">10</th>
                    <th className="heading-box">11</th>
                    <th className="heading-box">12</th>
                    <th className="heading-box">13</th>
                    <th className="heading-box">14</th>
                    <th className="heading-box">15</th>
                    <th className="heading-box">16</th>
                    <th className="heading-box">17</th>
                    <th className="heading-box">18</th>
                    <th className="heading-box">19</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="first-row">
                    <td>07:45 PM</td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                  </tr>
                  <tr className="second-row">
                    <td>07:45 PM</td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                  </tr>
                  <tr className="first-row">
                    <td>07:45 PM</td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                    <td>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                      <p>3451</p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Result2D_Modal;
