import React from "react";
import "./Win_Option.css";

function Win_Option({ options }) {
  return (
    <div className="win-option-list-holder">
      {options.map((option) => (
        <div key={option} className="win-option-holder">
          <h1>{option}</h1>
        </div>
      ))}
    </div>
  );
}

export default Win_Option;
