import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./Bet_table.css"

function Bet_table() {
  return (
    <section className="Bet_table">
      <div className="bet-table-holder">
        <div class="">
          <div class="">
            <div id="" class="table-holder" data-attr-tab-brand="">
              <div class="">
                <div class="bet-row" id="">
                  <div class="feild-box">
                    <div class="main-block-text heading-hox">
                      <p class="">BLOCK</p>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="B 0"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="1"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="2"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="3"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="4"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="5"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="6"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="7"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="8"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="9"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  <div class="feild-box">
                    <div class=" ">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="F 0"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">00</InputGroup.Text>
                        <Form.Control
                          placeholder="00"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">01</InputGroup.Text>
                        <Form.Control
                          placeholder="01"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">02</InputGroup.Text>
                        <Form.Control
                          placeholder="02"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">03</InputGroup.Text>
                        <Form.Control
                          placeholder="03"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">04</InputGroup.Text>
                        <Form.Control
                          placeholder="04"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">05</InputGroup.Text>
                        <Form.Control
                          placeholder="05"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">06</InputGroup.Text>
                        <Form.Control
                          placeholder="06"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">07</InputGroup.Text>
                        <Form.Control
                          placeholder="07"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">08</InputGroup.Text>
                        <Form.Control
                          placeholder="08"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">09</InputGroup.Text>
                        <Form.Control
                          placeholder="09"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="1"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">10</InputGroup.Text>
                        <Form.Control
                          placeholder="10"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">11</InputGroup.Text>
                        <Form.Control
                          placeholder="11"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">12</InputGroup.Text>
                        <Form.Control
                          placeholder="12"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">13</InputGroup.Text>
                        <Form.Control
                          placeholder="13"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">14</InputGroup.Text>
                        <Form.Control
                          placeholder="14"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">15</InputGroup.Text>
                        <Form.Control
                          placeholder="15"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">16</InputGroup.Text>
                        <Form.Control
                          placeholder="16"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">17</InputGroup.Text>
                        <Form.Control
                          placeholder="17"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">18</InputGroup.Text>
                        <Form.Control
                          placeholder="18"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">19</InputGroup.Text>
                        <Form.Control
                          placeholder="19"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="2"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">20</InputGroup.Text>
                        <Form.Control
                          placeholder="20"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">21</InputGroup.Text>
                        <Form.Control
                          placeholder="21"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">22</InputGroup.Text>
                        <Form.Control
                          placeholder="22"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">23</InputGroup.Text>
                        <Form.Control
                          placeholder="23"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">24</InputGroup.Text>
                        <Form.Control
                          placeholder="24"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">25</InputGroup.Text>
                        <Form.Control
                          placeholder="25"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">26</InputGroup.Text>
                        <Form.Control
                          placeholder="26"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">27</InputGroup.Text>
                        <Form.Control
                          placeholder="27"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">28</InputGroup.Text>
                        <Form.Control
                          placeholder="28"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">29</InputGroup.Text>
                        <Form.Control
                          placeholder="29"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="3"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">30</InputGroup.Text>
                        <Form.Control
                          placeholder="30"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">31</InputGroup.Text>
                        <Form.Control
                          placeholder="31"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">32</InputGroup.Text>
                        <Form.Control
                          placeholder="32"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">33</InputGroup.Text>
                        <Form.Control
                          placeholder="33"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">34</InputGroup.Text>
                        <Form.Control
                          placeholder="34"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">35</InputGroup.Text>
                        <Form.Control
                          placeholder="35"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">36</InputGroup.Text>
                        <Form.Control
                          placeholder="36"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">37</InputGroup.Text>
                        <Form.Control
                          placeholder="37"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">38</InputGroup.Text>
                        <Form.Control
                          placeholder="38"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">39</InputGroup.Text>
                        <Form.Control
                          placeholder="39"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="4"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">40</InputGroup.Text>
                        <Form.Control
                          placeholder="40"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">41</InputGroup.Text>
                        <Form.Control
                          placeholder="41"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">42</InputGroup.Text>
                        <Form.Control
                          placeholder="42"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">43</InputGroup.Text>
                        <Form.Control
                          placeholder="43"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">44</InputGroup.Text>
                        <Form.Control
                          placeholder="44"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">45</InputGroup.Text>
                        <Form.Control
                          placeholder="45"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">46</InputGroup.Text>
                        <Form.Control
                          placeholder="46"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">47</InputGroup.Text>
                        <Form.Control
                          placeholder="47"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">48</InputGroup.Text>
                        <Form.Control
                          placeholder="48"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">49</InputGroup.Text>
                        <Form.Control
                          placeholder="49"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="5"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">50</InputGroup.Text>
                        <Form.Control
                          placeholder="50"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">51</InputGroup.Text>
                        <Form.Control
                          placeholder="51"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">52</InputGroup.Text>
                        <Form.Control
                          placeholder="52"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">53</InputGroup.Text>
                        <Form.Control
                          placeholder="53"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">54</InputGroup.Text>
                        <Form.Control
                          placeholder="54"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">55</InputGroup.Text>
                        <Form.Control
                          placeholder="55"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">56</InputGroup.Text>
                        <Form.Control
                          placeholder="56"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">57</InputGroup.Text>
                        <Form.Control
                          placeholder="57"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">58</InputGroup.Text>
                        <Form.Control
                          placeholder="58"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">59</InputGroup.Text>
                        <Form.Control
                          placeholder="59"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="6"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">60</InputGroup.Text>
                        <Form.Control
                          placeholder="60"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">61</InputGroup.Text>
                        <Form.Control
                          placeholder="61"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">62</InputGroup.Text>
                        <Form.Control
                          placeholder="62"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">63</InputGroup.Text>
                        <Form.Control
                          placeholder="63"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">64</InputGroup.Text>
                        <Form.Control
                          placeholder="64"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">65</InputGroup.Text>
                        <Form.Control
                          placeholder="65"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">66</InputGroup.Text>
                        <Form.Control
                          placeholder="66"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">67</InputGroup.Text>
                        <Form.Control
                          placeholder="67"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">68</InputGroup.Text>
                        <Form.Control
                          placeholder="68"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">69</InputGroup.Text>
                        <Form.Control
                          placeholder="69"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="7"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">70</InputGroup.Text>
                        <Form.Control
                          placeholder="70"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">71</InputGroup.Text>
                        <Form.Control
                          placeholder="71"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">72</InputGroup.Text>
                        <Form.Control
                          placeholder="72"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">73</InputGroup.Text>
                        <Form.Control
                          placeholder="73"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">74</InputGroup.Text>
                        <Form.Control
                          placeholder="74"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">75</InputGroup.Text>
                        <Form.Control
                          placeholder="75"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">76</InputGroup.Text>
                        <Form.Control
                          placeholder="76"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">77</InputGroup.Text>
                        <Form.Control
                          placeholder="77"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">78</InputGroup.Text>
                        <Form.Control
                          placeholder="78"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">79</InputGroup.Text>
                        <Form.Control
                          placeholder="79"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="8"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">80</InputGroup.Text>
                        <Form.Control
                          placeholder="80"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">81</InputGroup.Text>
                        <Form.Control
                          placeholder="81"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">82</InputGroup.Text>
                        <Form.Control
                          placeholder="82"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">83</InputGroup.Text>
                        <Form.Control
                          placeholder="83"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">84</InputGroup.Text>
                        <Form.Control
                          placeholder="84"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">85</InputGroup.Text>
                        <Form.Control
                          placeholder="85"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">86</InputGroup.Text>
                        <Form.Control
                          placeholder="86"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">87</InputGroup.Text>
                        <Form.Control
                          placeholder="87"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">88</InputGroup.Text>
                        <Form.Control
                          placeholder="88"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">89</InputGroup.Text>
                        <Form.Control
                          placeholder="89"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div class="bet-row" id="">
                  {" "}
                  <div class="feild-box">
                    <div class="">
                      <InputGroup className="input-group-holder">
                        <Form.Control
                          className="heading-hox"
                          placeholder="9"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">90</InputGroup.Text>
                        <Form.Control
                          placeholder="90"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">91</InputGroup.Text>
                        <Form.Control
                          placeholder="91"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">92</InputGroup.Text>
                        <Form.Control
                          placeholder="92"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">93</InputGroup.Text>
                        <Form.Control
                          placeholder="93"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">94</InputGroup.Text>
                        <Form.Control
                          placeholder="94"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">95</InputGroup.Text>
                        <Form.Control
                          placeholder="95"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">96</InputGroup.Text>
                        <Form.Control
                          placeholder="96"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">97</InputGroup.Text>
                        <Form.Control
                          placeholder="97"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">98</InputGroup.Text>
                        <Form.Control
                          placeholder="98"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div class="feild-box">
                    <div id="" class=" ">
                      <InputGroup className="input-group-holder">
                        <InputGroup.Text id="basic-addon1">99</InputGroup.Text>
                        <Form.Control
                          placeholder="99"
                          aria-label=""
                          aria-describedby=""
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Bet_table;
