import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./GameBetCheckBox.css";
import ButtonCommon from "../../../Common-Component/ButtonCommon/ButtonCommon";

function GameBetCheckBox() {
  const [checkedState, setCheckedState] = useState(Array(60).fill(false));
  const [isActive, setIsActive] = useState([true, false, false]); // Modified isActive state

  const handleCheckboxChange = (index, isChecked) => {
    const updatedCheckedState = [...checkedState];
    updatedCheckedState[index] = isChecked;
    setCheckedState(updatedCheckedState);
  };

  const handleCheckboxOuterChange = (index) => {
    const updatedIsActive = Array(3).fill(false);
    updatedIsActive[index] = true;
    setIsActive(updatedIsActive);

    // Check or uncheck all the checkboxes based on the state of the main checkbox
    const allChecked = checkedState[index];
    const updatedCheckedState = checkedState.map((_, i) => allChecked);
    setCheckedState(updatedCheckedState);
  };

  // Determine the range of indices to show based on the active main checkbox
  const getActiveRange = () => {
    const activeIndex = isActive.findIndex((active) => active);
    if (activeIndex === -1) return [];
    if (activeIndex === 0) return [10, 20]; // 10 to 19
    if (activeIndex === 1) return [30, 50]; // 30 to 49
    if (activeIndex === 2) return [50, 60]; // 50 to 59
  };

  const [start, end] = getActiveRange();

  return (
    <>
      <div className="GameBetCheckBox">
        <div className="heading-holder">
          <h4>Select All</h4>
          <Form className="check-box-holder">
            <Form.Check
              label=""
              type="checkbox"
              onChange={(e) => {
                const allChecked = e.target.checked;
                const updatedCheckedState = Array(60).fill(allChecked);
                const updatedIsActive = Array(3).fill(allChecked);
                setCheckedState(updatedCheckedState);
                setIsActive(updatedIsActive);
              }}
              checked={checkedState.every(Boolean)}
            />
          </Form>
        </div>

        <div className="main-check-box-holder">
          <div className="row ">
            {[10, 30, 50].map((value, index) => (
              <div className="col-xl-4 col-md-4 col-sm-4 p-0" key={value}>
                <div
                  className={`main-check-box ${isActive[index] || checkedState[index] ? "active" : ""}`}
                  onClick={() => handleCheckboxOuterChange(index)}
                >
                  <ButtonCommon
                    className={"check-box-btn"}
                    text={
                      <>
                        <Form className="check-box-holder ">
                          <Form.Check
                            className={`${checkedState[index] ? "active" : ""}`}
                            label=""
                            type="checkbox"
                            checked={checkedState[index]}
                            onChange={(e) => handleCheckboxChange(index) }
                          />
                        </Form>
                        {value}
                      </>
                    }
                  />
                  <div className="top-border-holder"></div>
                </div>
              </div>
            ))}
          </div>

          <div className="all_checkbox">
            <div className="row">
              {start !== undefined &&
                end !== undefined &&
                [...Array(end - start).keys()].map((i) => {
                  const index = start + i;
                  return (
                    <div className="col-md-12" key={index}>
                      <div className="all-bet-bet-box">
                        <h4>{index}</h4>
                        <Form className="check-box-holder">
                          <Form.Check
                            label=""
                            type="checkbox"
                            checked={checkedState[index]}
                            onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                          />
                        </Form>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameBetCheckBox;
