import React from "react";
import "./Succsessfull_Modal.css";
import { Modal } from "react-bootstrap";

function Succsessfull_Modal(props) {
  return (
    <>
      <Modal
        className="Succsessfull_Modal modal-holder"
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="check-icon-holder">
            <img
              src={process.env.PUBLIC_URL + "/assets/image/Login/check.png"}
              className="check-icon"
            />
          </div>
          <h4>Login Successfully!</h4>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Succsessfull_Modal;
