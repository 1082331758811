import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import "./Footer.css";
import ButtonCommon from "../Common-Component/ButtonCommon/ButtonCommon";
import Report_Modal from "../Common-Component/Common-Modal/Report_Modal/Report_Modal";
import Result2D_Modal from "../Common-Component/Common-Modal/Result2D_Modal/Result2D_Modal";
import Result_3D_Modal from "../Common-Component/Common-Modal/Result_3D_Modal/Result_3D_Modal";

const Footer = () => {
  const location = useLocation(); // Get current location
  const [modalShow, setModalShow] = React.useState(false);
  const [modalResultShow, setResultModalShow] = React.useState(false);

  const is2DGame = location.pathname === "/game-2d"; // Check if current path is /game-2d
  const is3DGame = location.pathname === "/game-3d"; // Check if current path is /game-3d
  const is50AGame = location.pathname === "/game-50"; // Check if current path is /game-50

  return (
    <>
      <section className="Footer">
        <div className="Footer-holder">
          <ButtonCommon
            className={"footer-btn-holder"}
            background={
              "transparent linear-gradient(180deg, #FF0000 0%, #800000 100%)"
            }
            text={"Report"}
            onClick={() => setModalShow(true)}
          />

          <ButtonCommon
            className={"footer-btn-holder"}
            background={
              " transparent linear-gradient(180deg, #00B1FF 0%, #00223D 100%)"
            }
            text={"Reset"}
          />

          <ButtonCommon
            className={"footer-btn-holder"}
            background={
              "transparent linear-gradient(180deg, #FF00F7 0%, #3D0053 100%)"
            }
            text={"Result"}
            onClick={() => setResultModalShow(true)}
          />

          <ButtonCommon
            className={"footer-btn-holder"}
            background={
              "transparent linear-gradient(180deg, #4BFF00 0%, #116A00 100%)"
            }
            text={"Buy"}
          />

          {!is3DGame && !is50AGame && (
            <ButtonCommon
              className={"footer-btn-holder"}
              background={"#fff"}
              color={"#2AA2CE"}
              border={"1px solid #2AA2CE"}
              text={"Total QTY:00"}
            />
          )}

          <ButtonCommon
            className={"footer-btn-holder"}
            background={"#fff"}
            color={"#031054"}
            border={"1px solid #031054"}
            text={"Total PT:00"}
          />
        </div>
      </section>

      <Report_Modal
        headingText={"Report"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      {is2DGame && (
        <Result2D_Modal
          show={modalResultShow}
          onHide={() => setResultModalShow(false)}
        />
      )}

      {is3DGame && (
        <Result_3D_Modal
          show={modalResultShow}
          onHide={() => setResultModalShow(false)}
        />
      )}

      {is50AGame && (
        <Result_3D_Modal
          show={modalResultShow}
          onHide={() => setResultModalShow(false)}
        />
      )}
    </>
  );
};

export default Footer;
