import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import ButtonCommon from "../../ButtonCommon/ButtonCommon";
import "./Advance_Draw.css";

function Advance_Draw(props) {
  const { headingText, onHide } = props;
  const times = [
    "Now", "01:15 PM", "02:15 PM", "03:15 PM", "04:15 PM",
    "05:15 PM", "06:15 PM", "07:15 PM", "08:15 PM", "09:15 PM",
    "10:15 PM", "11:15 PM", "12:15 PM", "13:15 PM", "14:15 PM",
    "15:15 PM", "16:15 PM", "17:15 PM", "18:15 PM", "19:15 PM"
  ];

  const [selectedTimes, setSelectedTimes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTimes([]);
    } else {
      setSelectedTimes(times);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (time) => {
    setSelectedTimes(prevSelectedTimes =>
      prevSelectedTimes.includes(time)
        ? prevSelectedTimes.filter(t => t !== time)
        : [...prevSelectedTimes, time]
    );
  };

  return (
    <Modal
      className="Logout_Modal modal-holder Advance_Draw"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {headingText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bet-time-selection">
          <div className="select-time-holder">
            <Form className="check-box-holder">
              {times.map((time, index) => (
                <Form.Check
                  key={index}
                  label={time}
                  type="checkbox"
                  checked={selectedTimes.includes(time)}
                  onChange={() => handleCheckboxChange(time)}
                />
              ))}
            </Form>
          </div>
        </div>
        <div className="select-all-check-box">
          <Form className="check-box-holder">
            <Form.Check
              label="Select All"
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </Form>
        </div>
        <div className="no_of_draw_holder">
          <Form className="check-box-holder">
            <Form.Label>No of Draws</Form.Label>
            <Form.Control type="text" value={selectedTimes.length} readOnly />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-btn-holder">
          <ButtonCommon
            className={"footer-btn footer-close-btn"}
            text={"Close"}
            onClick={onHide}
          />
          <ButtonCommon
            className={"footer-btn footer-ok-btn"}
            text={"OK"}
            onClick={onHide}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default Advance_Draw;
