import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import "./Game2D.css";
import ButtonCommon from "../../../Common-Component/ButtonCommon/ButtonCommon";
import Bet_table from "../Bet_table/Bet_table";
import Advance_Draw from "../../../Common-Component/Common-Modal/Advance_Draw/Advance_Draw";
import GameBetCheckBox from "../GameBetCheckBox/GameBetCheckBox";
import Timer_Holder from "../../../Common-Component/Timer_Holder/Timer_Holder";

function Game2D() {
  const [modalShow, setModalShow] = useState(false);



 
  const selectNumberTypes = ["All", "Even", "Odd"];

  const data = [
    {
      quantities: [10, 30, 50],
      values: [
        { id: "sangam_qty", value: 0 },
        { id: "new4_qty", value: 0 },
        { id: "new14_qty", value: 0 },
      ],
    },
    {
      values: [
        { id: "chetak_amt", value: 0, active: true },
        { id: "new5_amt", value: 0, active: true },
        { id: "new15_amt", value: 0, active: true },
      ],
    },
    {
      values: [
        { id: "super_amt", value: 0 },
        { id: "new6_amt", value: 0 },
        { id: "new16_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "chetak_amt", value: 0, active: true },
        { id: "new5_amt", value: 0, active: true },
        { id: "new15_amt", value: 0, active: true },
      ],
    },
    {
      values: [
        { id: "super_amt", value: 0 },
        { id: "new6_amt", value: 0 },
        { id: "new16_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "chetak_amt", value: 0, active: true },
        { id: "new5_amt", value: 0, active: true },
        { id: "new15_amt", value: 0, active: true },
      ],
    },
    {
      values: [
        { id: "super_amt", value: 0 },
        { id: "new6_amt", value: 0 },
        { id: "new16_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "chetak_amt", value: 0, active: true },
        { id: "new5_amt", value: 0, active: true },
        { id: "new15_amt", value: 0, active: true },
      ],
    },
    {
      values: [
        { id: "super_amt", value: 0 },
        { id: "new6_amt", value: 0 },
        { id: "new16_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "chetak_amt", value: 0, active: true },
        { id: "new5_amt", value: 0, active: true },
        { id: "new15_amt", value: 0, active: true },
      ],
    },
  ];

  const pointsData = [
    {
      points: [10, 30, 50],
      values: [
        { id: "sangam_amt", value: 0 },
        { id: "new4_amt", value: 0 },
        { id: "new14_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "chetak_amt", value: 0, active: true },
        { id: "new5_amt", value: 0, active: true },
        { id: "new15_amt", value: 0, active: true },
      ],
    },
    {
      values: [
        { id: "super_amt", value: 0 },
        { id: "new6_amt", value: 0 },
        { id: "new16_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "deluxe_amt", value: 0, active: true },
        { id: "new7_amt", value: 0, active: true },
        { id: "new17_amt", value: 0, active: true },
      ],
    },
    {
      values: [
        { id: "bhagya_amt", value: 0 },
        { id: "new8_amt", value: 0 },
        { id: "new18_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "diamond_amt", value: 0, active: true },
        { id: "new9_amt", value: 0, active: true },
        { id: "new19_amt", value: 0, active: true },
      ],
    },
    {
      values: [
        { id: "lucky_amt", value: 0 },
        { id: "new10_amt", value: 0 },
        { id: "new20_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "new1_amt", value: 0, active: true },
        { id: "new11_amt", value: 0, active: true },
        { id: "new21_amt", value: 0, active: true },
      ],
    },
    {
      values: [
        { id: "new2_amt", value: 0 },
        { id: "new12_amt", value: 0 },
        { id: "new22_amt", value: 0 },
      ],
    },
    {
      values: [
        { id: "new3_amt", value: 0, active: true },
        { id: "new13_amt", value: 0, active: true },
        { id: "new23_amt", value: 0, active: true },
      ],
    },
  ];

  return (
    <>
      <section className="Game">
        <div className="row me-0 ms-0">
          <div className=" col-xl-2 col-lg-2 col-md-12">
            <GameBetCheckBox />
          </div>
          <div className="col-xl-10 col-lg-10 col-md-12">
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 col-sm-3 ">
                    <div className="select-game">
                      <div className="heading-holder">
                        <h4>Select Random</h4>
                      </div>
                      <Form className="check-box-holder select-number-type ">
                        {selectNumberTypes.map((type, index) => (
                          <Form.Check
                            label={type}
                            type="checkbox"
                            key={index}
                          />
                        ))}
                      </Form>
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-5">
                    <div className="select-game games-selected-info-holder">
                      <div className="row me-0 ms-0">
                        <div className="col-md-6 col-sm-6 border-right-holder">
                          <div className="text-holder">
                            <p>Cur. Draw Time: 02.15PM</p>
                          </div>

                          <div className="text-holder">
                            <p>Draw Date: 16/04/24</p>
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-6">
                          <div className="text-holder">
                            <p>Next Game: 02.30 PM</p>
                          </div>

                          <div className="text-holder">
                            <p>Coupon Value: 2</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2 px-md-2 p-sm-0">
                    <div className="games-Advance-Draw-holder select-game">
                      <div className="heading-holder">
                        <h4>Family</h4>
                        <Form className="switch-box-holder">
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </div>
                      <div className="game-Advance-Draw">
                        <div className="Advance-Draw-btn-holder">
                          <ButtonCommon
                            className="Advance-Draw-btn"
                            text="Advance Draw(F7)"
                            onClick={() => setModalShow(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <div className="games-timer-holder select-game">
                    <Timer_Holder/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12">
                <Bet_table />
              </div>

              <div className="col-xl-4 col-lg-4 col-md-12">
                <div class="total-qty-holder">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 ">
                      <div class="desk_qty_table table-qty">
                        <Table className="mb-0">
                          <tbody>
                            <tr class="desk_qty_table_head2">
                              <td class="qty_table_heading_holder">
                                <div class="qty_heading">
                                  <h4>Quantity</h4>
                                </div>
                                <div class="qty_series_main">
                                  {data[0].quantities.map((qty, index) => (
                                    <p key={index} className="qty_series_no">
                                      {qty}
                                    </p>
                                  ))}
                                </div>
                              </td>
                            </tr>

                            {data.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td className="text-end">
                                  <div className="qty_series_no_val_main">
                                    {row.values.map((item, index) => (
                                      <div
                                        key={index}
                                        className={`qty_series_no_val double_amt_div ${
                                          item.active ? "active" : ""
                                        }`}
                                        id={item.id}
                                      >
                                        {item.value}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 ">
                      <div class="desk_qty_table table-point">
                        <Table className="mb-0">
                          <tbody>
                            <tr class="desk_qty_table_head2">
                              <td class="qty_table_heading_holder">
                                <div class="qty_heading">
                                  <h4>Points</h4>
                                </div>
                                <div class="qty_series_main">
                                  {pointsData[0].points.map((point, index) => (
                                    <p key={index} className="qty_series_no">
                                      {point}
                                    </p>
                                  ))}
                                </div>
                              </td>
                            </tr>
                            {pointsData.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td className="text-end">
                                  <div className="qty_series_no_val_main">
                                    {row.values.map((item, index) => (
                                      <div
                                        key={index}
                                        className={`qty_series_no_val double_amt_div ${
                                          item.active ? "active" : ""
                                        }`}
                                        id={item.id}
                                      >
                                        {item.value}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Advance_Draw
        headingText={"Advance Draw"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Game2D;
